<template>
  <div class="parking-data-wrapper">
    <title-bar :title="'停车数据分析'"></title-bar>
    <div class="page-box">
      <div class="page-select">
        <div class="select-item" @click="showDateSelect = true">
          {{ reqData.startDate }} ~ {{ reqData.endDate }} <i></i>
        </div>
      </div>
      <div class="page-content">
        <div class="page-scroll-box">
          <div class="content-title">
            <div class="title-item" @click="showParkingPicker = true">
              <i></i> <span>{{ parkingTxt }}</span>
            </div>
            <div class="title-item item-sort" @click="showSortPicker = true">
              <span>按</span>

              <span class="sort-txt">{{ sortTxt }}</span>
              <i></i>
            </div>
          </div>

          <div class="content-item-box">
            <div class="item-scroll">
              <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list
                  v-model="loading"
                  :finished="finished"
                  :immediate-check="false"
                  finished-text="没有更多了"
                  @load="getRecord"
                >
                  <div class="item-box">
                    <div
                      class="content-item"
                      v-for="(item, index) in list"
                      :key="index"
                    >
                      <div class="item-left">
                        <div class="item-name">{{ item.parkingName }}</div>
                        <div class="item-date">{{ item.dateDay }}</div>
                      </div>
                      <div class="item-right">
                        <div class="item-record">{{ item.recordNum }}条</div>
                        <div class="item-average">
                          <span>每车位平均</span> {{ item.avaNum }}条
                        </div>
                      </div>
                    </div>
                  </div>
                </van-list>
              </van-pull-refresh>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 车场选择器 -->
    <van-popup v-model="showParkingPicker" round position="bottom">
      <tab-select
        :isShowAll="false"
        @selectAll="onSelectAll"
        @selectFinish="onSelectFinish"
      ></tab-select>
    </van-popup>

    <!-- 排序选择器 -->
    <van-popup v-model="showSortPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="showSortPicker = false"
        @confirm="onSortConfirm"
      />
    </van-popup>

    <!-- 日期选择器 -->
    <van-calendar
      v-model="showDateSelect"
      type="range"
      color="#199ed8"
      :allow-same-day="true"
      :min-date="new Date(2000, 1, 1)"
      :max-date="new Date()"
      :default-date="defaultDate"
      @confirm="onDateConfirm"
    />
  </div>
</template>

<script>
import TabSelect from "../views/tabSelect.vue";

export default {
  components: {
    TabSelect
  },
  data: () => ({
    showSortPicker: false,
    showDateSelect: false,
    showParkingPicker: false,
    reqData: {}, // 请求返回数据
    parkingColumns: [], // 车场选择器数据
    parkingTxt: "全部", // 车场选择器区域显示文字

    // 排序选择器数据
    columns: [
      { text: "时间升序", sortOrder: "ascend", sortField: "dateDay" },
      { text: "时间降序", sortOrder: "descend", sortField: "dateDay" },
      { text: "停车记录数升序", sortOrder: "ascend", sortField: "recordNum" },
      { text: "停车记录数降序", sortOrder: "descend", sortField: "recordNum" },
      {
        text: "每个车位停车平均数升序",
        sortOrder: "ascend",
        sortField: "avaNum"
      },
      {
        text: "每个车位停车平均数降序",
        sortOrder: "descend",
        sortField: "avaNum"
      }
    ],
    sortTxt: "时间升序",
    defaultDate: null, // 默认选择日期

    list: [],
    loading: false,
    finished: false,
    refreshing: false
  }),
  methods: {
    onSelectAll() {
      this.parkingTxt = this.parkingColumns[0].text;

      this.reqData.tenantId = this.parkingColumns[0].id;
      this.reqData.streetCode = null;

      this.onSelfRefresh();

      this.showParkingPicker = false;
    },

    onSelectFinish(obj) {
      this.parkingTxt = obj.txt;

      this.reqData.tenantId = obj.id0;
      this.reqData.streetCode = obj.id1;

      this.onSelfRefresh();

      this.showParkingPicker = false;
    },
    onParkingConfirm(value) {
      this.parkingTxt = value.text;

      this.reqData.tenantId = value.id;

      this.onSelfRefresh();
      this.showParkingPicker = false;
    },
    onSortConfirm(value) {
      this.sortTxt = value.text;

      this.reqData.sortField = value.sortField;
      this.reqData.sortOrder = value.sortOrder;

      this.onSelfRefresh();
      this.showSortPicker = false;
    },

    onDateConfirm(date) {
      this.reqData.startDate = this.dateFilter(date[0]);
      this.reqData.endDate = this.dateFilter(date[1]);

      this.onSelfRefresh();
      this.showDateSelect = false;
    },

    dateFilter(date) {
      let _date = new Date(date);
      return `${_date.getFullYear()}-${
        _date.getMonth() + 1 < 10
          ? "0" + (_date.getMonth() + 1)
          : _date.getMonth() + 1
      }-${_date.getDate() < 10 ? "0" + _date.getDate() : _date.getDate()}`;
    },

    async reqParkingColumns() {
      let { data } = await this.$api.getTenant();

      let totalId = "";
      this.parkingColumns = data.map(item => {
        totalId += item.id + ",";
        item.text = item.name;
        return item;
      });

      this.parkingColumns.unshift({
        text: "全部",
        id: totalId
      });

      this.parkingTxt = this.parkingColumns[0]["text"];
      this.reqData.tenantId = this.parkingColumns[0]["id"];
    },

    async getRecord() {
      if (this.refreshing) {
        this.list = [];
        this.reqData.pageNum = 1;
        this.refreshing = false;
      }

      let { data } = await this.$api.getRecord(this.reqData);
      data.records.forEach(item => {
        this.list.push(item);
      });

      this.reqData.pageNum++;
      this.total = data.total;
      this.loading = false;

      if (this.list.length >= this.total) {
        this.finished = true;
      }
    },

    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getRecord();
    },

    onSelfRefresh() {
      this.refreshing = true;
      this.onRefresh();
    },

    async reqInit() {
      await this.reqParkingColumns();
      this.onSelfRefresh();
    },

    pageInit() {
      // 设定日历组件默认选择日期
      let newDate = new Date();
      let startDate = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
      let endDate = newDate;
      this.defaultDate = [startDate, endDate];

      // 将默认日期同步到请求对象中
      this.reqData.startDate = this.dateFilter(startDate);
      this.reqData.endDate = this.dateFilter(endDate);

      this.reqInit();
    }
  },

  created() {
    this.pageInit();
  }
};
</script>

<style lang="less" scoped>
.parking-data-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.page-box {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  background-color: #f0f2f5;
  overflow: hidden;
}

.page-select {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 0;

  .select-item {
    display: flex;
    align-items: center;

    i {
      display: block;
      width: 0;
      height: 0;
      margin: 0 4px;
      border-top: 6px solid #000;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      border-bottom: 0;
    }
  }
}

.page-content {
  flex: 1;
  width: 100%;
  overflow: hidden;
}

.page-scroll-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-bottom: 12px;
  border-radius: 6px;
  overflow: hidden;
}

.content-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  background-color: #199ed8;
  color: #fff;
  font-size: 13px;

  i {
    display: block;
    width: 0;
    height: 0;
    margin: 0 4px;
    border-top: 6px solid #fff;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-bottom: 0;
  }

  .title-item {
    display: flex;
    align-items: center;

    &:first-child {
      flex: 0 0 56%;
      overflow: hidden;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &.item-sort {
      flex: 0 0 40%;
      justify-content: flex-end;
      overflow: hidden;

      .sort-txt {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.content-item-box {
  flex: 1;
  width: 100%;
  overflow: hidden;
  position: relative;

  .item-scroll {
    width: 100%;
    height: 100%;

    overflow-x: hidden;
    overflow-y: scroll;
  }

  .item-box {
    background-color: #fff;
    padding: 0 14px 0 14px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .content-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px 0;
    border-bottom: 2px solid #f8f8f8;

    &:last-child {
      border: 0;
    }
  }

  .content-end {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
  }

  .item-left {
    flex: 0 0 50%;

    .item-name {
      // height: 22px;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 4px;
    }

    .item-date {
      font-size: 13px;
      color: #666;
    }
  }

  .item-right {
    flex: 1;
    text-align: right;

    .item-record {
      height: 22px;
      font-size: 14px;
      font-weight: bold;
      line-height: 22px;
      margin-bottom: 4px;
    }

    .item-average {
      font-size: 13px;

      span {
        color: #666;
      }
    }
  }
}

.van-list__finished-text {
  background-color: #fff;
}
</style>
