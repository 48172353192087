<template>
  <div class="tabSelect-wrapper">
    <van-cascader
      :options="list"
      :field-names="fieldNames"
      :closeable="false"
      active-color="#003399"
      @finish="onSelectEnd"
    >
      <template #title>
        <div class="title">
          <div class="title-txt">请选择车场</div>
          <div class="title-total" @click="onSelectAll">查看全部</div>
        </div>
      </template>
    </van-cascader>
  </div>
</template>

<script>
export default {
  props: {
    isShowAll: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    list: [],

    fieldNames: {
      text: "text",
      value: "id"
    }
  }),

  methods: {
    async initPageData() {
      let { data } = await this.$api.getTenantAndStreetAndParkingInfo();

      this.list = data;

      if (!this.isShowAll) {
        this.list.forEach(item => {
          item.children.forEach(_ => delete _.children);
        });
      }
    },

    onClickTreeSelect(data) {
      console.log(data);

      this.activeId = data.id;
    },

    onSelectEnd({ selectedOptions }) {
      let obj = {};
      let str = "";
      console.log(selectedOptions);

      // 将结果数组拼接成字符串
      selectedOptions.forEach((item, index) => {
        str += item.text + "-";
        obj["id" + index] = item.id;
      });
      obj.txt = str.slice(0, -1);

      this.$emit("selectFinish", obj);
    },

    onSelectAll() {
      this.value = "";
      this.$emit("selectAll");
    }
  },

  mounted() {
    this.initPageData();
  }
};
</script>

<style lang="less" scoped>
@tree-select-item-active-color: #19bdff;
.tabSelect-wrapper {
  width: 100%;
  height: 100%;
  // background-color: #f7f8fa;
  // overflow: hidden;
}

.select-box {
  height: 80%;
  overflow: hidden;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  .title-txt {
    font-size: 16px;
  }

  .title-total {
    margin-left: 14px;
    padding: 4px 16px;
    border-radius: 20px;
    background-color: skyblue;
    color: #fff;
    font-size: 14px;
  }
}
</style>
